import * as R from 'ramda'
import {formatNumberPretty} from './formatNumberPretty'
import {formatHEConomics} from './formatHEConomics'

/**
 * The HEConomics UI and Redux state model store numbers and empty
 * or special strings that need to be formatted for user presentation.
 *
 * This function handles non-number special cases, and passes valid
 * numbers to formatNumberPretty.
 *
 * @param isCurrency boolean
 * @param numDecimalDigits number
 * @param number number | string
 * @returns a pretty formatted number, or '-', which indicates no data
 * @author Keith Elliott, 9/1/2021
 */
export const formatHEConomicsNumber = R.curry(
  (isCurrency: boolean, numDecimalDigits: number, x: number | string) => {
    return formatHEConomics(x => {
      if (typeof x === 'number')
        return isNaN(x)
          ? '-'
          : formatNumberPretty(isCurrency, numDecimalDigits, x)

      throw new Error(
        'Invalid number provided to formatHEConomicsNumber.  A number or empty string is expected, ' +
          'however the following value, of type ' +
          typeof x +
          ', was provided:  ' +
          x,
      )
    }, x)
  },
)
