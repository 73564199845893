import * as React from 'react'
// import {Table} from 'evergreen-ui'
// import PropTypes from 'prop-types'

type RowTitleProps = {
  title: string
  children?: React.ReactNode
}

const RowTitle = ({title, children}: RowTitleProps) => (
  <td className="row-title">
    <div className="row-title">
      {title}
      {children}
    </div>
  </td>
)

// RowTitle.propTypes = {
//   title: PropTypes.string.isRequired,
// };

export default RowTitle
