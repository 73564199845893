import * as React from 'react'
import {isShowingOPEXModal} from '../actions'
import {useDispatch} from 'react-redux'

type SectionHeaderProps = {
  title: string
  cssClassNameHeader?: string
  cssClassNameHeaderText?: string
  customAction: boolean
}

const SectionHeader = ({
  title,
  cssClassNameHeader = 'case-data-header',
  cssClassNameHeaderText = 'case-data-header-text',
  customAction = false
}: SectionHeaderProps) => {

  const dispatch = useDispatch()

  const showOPEXModal = () => {
    dispatch(isShowingOPEXModal(true))
  }
  
  return (
  <tr style={{height: 35}}>
    <td style={{width: '100%'}} colSpan={9} className={cssClassNameHeader}>
      <div className={cssClassNameHeaderText}>
        {title}
        {customAction === true && " - "}
        {customAction === true && <button onClick={showOPEXModal} style={{backgroundColor: 'transparent', color: 'white', padding: '0 5px', border: 0, fontStyle: 'italic'}}>Enter Custom Gross Fixed Well Cost Schedule</button>}
      </div>
      
    </td>
  </tr>
)}

export default SectionHeader
