import {currencyFormatter, numberFormatter} from '.'

export const formatNumberPretty = (
  isCurrency: boolean,
  numDecimalDigits: number,
  number: number,
) => {
  if (typeof number !== 'number')
    throw new Error(
      'Invalid number provided to formatNumberPretty.  A number is expected, ' +
        'however the following value was provided:  ' +
        number,
    )

  return isCurrency
    ? currencyFormatter(number, numDecimalDigits)
    : numberFormatter(number, numDecimalDigits)
}
