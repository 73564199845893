import * as R from 'ramda'

/**
 * The HEConomics UI and Redux state model store numbers and empty
 * or special strings that need to be formatted for user presentation.
 *
 * This function handles the general flow of formatting the data, which
 * includes data check and error throwing.
 *
 * Specific makePretty functions, passed in as an argument, handle
 * the actual formatting.
 *
 * @param makePretty Function that will be called to perform the formatting
 * @param number number | string | undefined | null
 * @returns a pretty formatted number, or '-', which indicates no data
 * @author Keith Elliott, 10/1/2021
 */
export const formatHEConomics = R.curry(
  (
    makePretty: (
      x: number | string | undefined | null,
    ) => number | string | undefined,
    x: number | string | null | undefined,
  ) =>
    x === undefined || x === null || x === '' || x === 'NA'
      ? '-'
      : makePretty(x),

  // if (typeof number === 'number')
  //   return isNaN(number)
  //     ? '-'
  //     : formatNumberPretty(isCurrency, numDecimalDigits, number)

  // throw new Error(
  //   'Invalid number provided to formatNumberHEConomics.  A number or empty string is expected, ' +
  //     'however the following value, of type ' +
  //     typeof number +
  //     ', was provided:  ' +
  //     number,
  // )
)
